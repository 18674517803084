import { useEffect, useState } from "react";
import "./App.css";
import Login from "./Pages/Login";
import Shop from "./Components/Shop";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import img from "./img/small-screen.svg";

const App = () => {
  //code to disable app display on screens smaller than 1024px
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1024);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isSmallScreen]);

  return (
    <div className="App">
      <>
          <Router>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/" element={<Shop isSmallScreen={isSmallScreen}/>} />
              <Route path="/home" element={<Shop isSmallScreen={isSmallScreen}/>} />
            </Routes>
          </Router>
        </>
    </div>
  );
};

export default App;
