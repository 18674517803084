import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import SideBar from "./SideBar";
import Details from "../Components/Details";
import TopSect from "../Components/TopSect";
import Cart from "../Components/Cart";

const Shop = ({isSmallScreen}) => {
  const [show, setShow] = useState(true);
  const [cart, setCart] = useState<any[]>([]);
  const navigateTo = useNavigate();
  const handleChange = (item: any, d: number) => {
    setCart((prevCart) =>
      prevCart.map((cartItem) => {
        if (cartItem.id === item.id) {
          const updatedAmount = cartItem.amount + d;
          return { ...cartItem, amount: updatedAmount > 0 ? updatedAmount : 1 };
        }
        return cartItem;
      })
    );
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) navigateTo("/login");
  }, []);

  return (
    <>
      {/* <SideBar /> */}
      <section className="flex flex-col w-full bg-bgColor">
        <React.Fragment>
          <TopSect setShow={setShow} size={cart.length} />
          {show && !isSmallScreen ? (
            <Details />
          ) : (
            <Cart cart={cart} setCart={setCart} handleChange={handleChange} />
          )}
        </React.Fragment>
      </section>
      <ToastContainer />
    </>
  );
};

export default Shop;
